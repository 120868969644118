<template>
  <AppLayout v-slot="{}">
    <Breadcrumbs />
    <section class="login">
      <b-container>
        <b-row>
          <b-col lg="10" xl="8" class="position-relative mx-auto">

            <span class="login-title bg-info text-white">{{ $t("login") }}</span>

            <div class="login-component">
              <div class="d-flex flex-column flex-md-row justify-content-between">

                <div class="info login--inner-component pb-25 pb-md-0">

                  <div>
                    <span class="title d-block">{{ $t("new_customers") }}</span>
                    <p class=""> {{ $t("register_info") }} </p>
                  </div>

                  <div class="text-right pb-25 py-4">
                    <b-button @click="$router.push({ name: 'create-account' })" variant="warning"
                      class="text-white py-3 px-15 w-100 rounded">{{ $t("create_account") }}</b-button>
                  </div>
                </div>

                <div class="form login--inner-component pt-25 pt-md-0">

                  <span class="title d-block">{{ $t("registered_customers") }}</span>
                  <p class="form-description d-block mb-30">{{ $t("account_login") }}</p>

                  <b-form @submit.prevent="onLoginFormSubmit" novalidate>

                    <b-form-group id="email-group" :label="$t('req_email') + '*'" label-for="email">
                      <b-form-input id="email" :placeholder="$t('req_email')" v-model="loginData.email"
                        :state="loginValidation.emailState" @blur="validateEmail" type="email" required=""
                        aria-describedby="email-feedback"></b-form-input>
                      <b-form-invalid-feedback id="email-feedback"> {{ loginValidation.emailError }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="password-group" :label="$t('req_password') + '*'" label-for="password">
                      <b-form-input id="password" :placeholder="$t('req_password')" v-model="loginData.password"
                        type="password" required="" :state="loginValidation.passwordState" @blur="validatePassword"
                        aria-describedby="password-feedback"></b-form-input>
                      <b-form-invalid-feedback id="password-feedback"> {{ loginValidation.passwordError }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <p class="form-info mb-15 d-block">{{ $t("required_fields") }}</p>

                    <div class="">
                      <b-button type="submit" variant="warning" class="w-100 text-white rounded form-submit-btn">
                        {{ $t("login") }}
                      </b-button>
                      <b-link to="/forgot-password" class="font-weight-normal link-underline">
                        {{ $t("password_forgot") }}
                      </b-link>

                    </div>

                  </b-form>
                </div>

              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </AppLayout>
</template>
<style lang="scss">
section.login {
  .login-title {
    width: 465px;
    border-radius: 5px;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
  }

  .login-component {
    border: 2px solid #408309;
    border-radius: 20px;
  }

  .form-control {
    border: 1px solid $orange;
    font-size: 14px !important;
  }

  .login--inner-component.info {
    padding-inline: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    span {
      font-size: 23.25px;
      font-style: italic;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
    }
  }

  .login--inner-component.form {
    padding-inline: 60px;


    label {
      font-size: 14.8px;
      font-weight: 400;
    }

    span {
      font-size: 23.25px;
      font-style: italic;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    p {
      font-size: 15.86px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}
</style>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout.vue";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import Login from "@storefront/core/modules/user/mixins/login";

export default {
  name: "Login",
  mixins: [Login],
  components: {
    AppLayout,
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("login") },
        { name: "keywords", content: this.$t("login") },
        { name: "description", content: this.$t("login") },
      ],
      title: this.$t("login"),
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("login"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loginValidation: {
        emailState: null,
        emailError: "",
        passwordState: null,
        passwordError: "",
      },
    };
  },
  methods: {
    onLoginFormSubmit() {
      let formValid = true;
      if (this.loginValidation.emailState != true) {
        this.validateEmail();
        if (this.loginValidation.emailState != true) {
          formValid = false;
        }
      }

      if (this.loginValidation.passwordState != true) {
        this.validatePassword();
        if (this.loginValidation.passwordState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        this.login();
      }
    },
    validateEmail() {
      if (this.loginData.email.length == 0) {
        this.loginValidation.emailState = false;
        this.loginValidation.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.loginData.email)) {
        this.loginValidation.emailState = false;
        this.loginValidation.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.loginValidation.emailState = true;
      return true;
    },

    validatePassword() {
      if (this.loginData.password.length == 0) {
        this.loginValidation.passwordState = false;
        this.loginValidation.passwordError = this.$t(
          "password_is_required_field"
        );
        return false;
      }
      this.loginValidation.passwordState = true;
      return true;
    },
  },
};
</script>
